<template>
  <div>
   <header-style title="Transactions"/>
   <div class="transaction ">
    <b-card-group deck>
    <b-card
    >
      <b-card-text class="title-transaction">Total Transaction Amount</b-card-text>
       <h2 class="transaction-price">530,340 <span class="currency">SAR</span></h2>
    </b-card>
<b-card>

  <b-card-text class="title-transaction">Total Transaction Count</b-card-text>
  <h2 class="transaction-price">235 <span class="currency">TRX</span></h2>
</b-card>

<b-card>
  <b-card-text class="title-transaction">Total Revenue Amount</b-card-text>
  <h2 class="transaction-price">750,450 <span class="currency">SAR</span></h2>
</b-card>
</b-card-group>

<div class="user-list-table-transaction">
  <b-card class="card-filter">
    <div class="d-flex justify-content-between p-2">
      <b-card-text class="title-transaction-filter d-flex">Transactions <span
          style="color:#5D636F;font-size:14px;padding-inline-start:5px" class="d-flex align-items-center">| Test Mode
          <label class="switch">
            <input type="checkbox">
            <span class="slider round"></span>
          </label>
        </span>
      </b-card-text>
      <div>
        <b-dropdown size="" variant="transparent3" toggle-class="text-decoration-none" no-caret class="p-0">
          <template #button-content class="">
            <i class="fas fa-columns"></i> Columns
          </template>
          <b-form-group
      v-slot="{ ariaDescribedby }"
    >
      <b-form-checkbox
        v-for="option in options"
        v-model="selected"
        :key="option.value"
        :value="option.value"
        :aria-describedby="ariaDescribedby"
        name="flavour-3a"
        class="columns-checkbox "
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>
  </b-dropdown>
        <!-- <b-dropdown split toggle-class="text-decoration-none" no-caret>
          <template #button-content>
             <i class="fas fa-arrow-circle-down"></i> hello
            </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <b-button class="btn-filter" v-b-toggle.collapse-1>  <i class="fas fa-filter"></i>Filter</b-button>
        <b-button variant="blue"> <i class="fas fa-arrow-circle-down"></i>Export</b-button>
      </div>
    </div>
    <div class="">
      <b-collapse id="collapse-1" class="">
        <div class="">
  <b-dropdown id="dropdown-1" text="Date" class="mx-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-2" text="Projects" class="m-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-3" text="Payment Gateway" class="m-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-4" text="Payment Method" class="m-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-5" text="Status" class="mx-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-6" text="Merchant" class="mx-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-7" text="Customer" class="m-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-8" text="Country" class="m-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-9" text="Currency" class="m-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
  <b-dropdown id="dropdown-10" text="Value" class="mx-md-2" variant="white">
    <!-- <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
  </b-dropdown>
</div>
   <div align="end" class=" my-3 mx- px-2">
  <b-button class="btn-close m-2" v-b-toggle.collapse-1>Close</b-button>
  <b-button variant="green-dark" class="p-3">Apply Filter</b-button>
 </div>
</b-collapse>
</div>

    </b-card>
    <div class="user-list-table">
   <main-table
        :fields="fields"
        :items="items"
        createRouteName=""
      />
      <div class="py-2 px-4 d-flex justify-content-between">
        <div class="pages">
      <p>Row per page</p>
      <div class="dropdown">
  <b-dropdown split text='20' class="" variant="transparent">
    <b-dropdown-item href="#">1</b-dropdown-item>
    <b-dropdown-item href="#">2</b-dropdown-item>
    <b-dropdown-item href="#">3</b-dropdown-item>
  </b-dropdown>
</div>
    </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        align="right"
        prev-text="Prev"
      next-text="Next"
      ></b-pagination>

    </div>
   </div>
  </div>
    </div>

  </div>
</template>
<script>
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
export default {
  components: { HeaderStyle },
  name: 'Transaction',
  data () {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: 'TRX ID', value: 'TRX ID' },
        { text: 'Operation ID', value: 'Operation ID' },
        { text: 'Value (SAR)', value: 'Value (SAR)' },
        { text: 'Project', value: 'Project' },
        { text: 'Status', value: 'Status' },
        { text: 'Customer Name', value: 'Customer Name' },
        { text: 'Customer Name', value: 'Customer Name' },
        { text: 'Customer Name', value: 'Customer Name' },
        { text: 'Payment Method', value: 'Payment Method' }
      ],
      mainProps: { blank: true, blankColor: '#777', width: 120, height: 120, class: 'm1' },
      fields: [
        { key: 'TRX', label: 'TRX ID', type: 'number' },
        { key: 'operationId', label: 'Operation ID', type: 'number' },
        { key: 'value', label: 'Value(SAR)', type: 'number' },
        { key: 'project', label: this.Project },
        { key: 'status', label: 'Status', type: 'status' },
        { key: 'customerName', label: 'Customer Name' },
        { key: 'paymentMethod', label: 'Payment Method' },
        { key: 'paymentGateway', label: 'Payment Gateway' }
      ],
      items: [
        { TRX: '#201122', operationId: '#201122', value: '10,000,000', project: 'smart saving', status: 'success', customerName: 'Ahmed Ibrahim', paymentMethod: 'Visa', paymentGateway: 'Noon' },
        { TRX: '#215678', operationId: '#215678', value: '125,550', project: 'IBBIL', status: 'Failed', customerName: 'Mostafa Mohamed', paymentMethod: 'Apple Pay', paymentGateway: 'Opay' },
        { TRX: '#227898', operationId: '#227898', value: '123,567', project: 'DPA', status: 'Pending', customerName: 'Bandar', paymentMethod: 'HyperPay', paymentGateway: 'HyperPay' }
      ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  }
}
</script>

<style >
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D1D6DE;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .7s;
}

input:checked + .slider {
  background-color: #0ABA68;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(30px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.columns-checkbox{
  margin: 4px;
}

.btn-close{
  background-color: transparent !important;
  color: black;
  border: none;
}
.btn-close:hover{
  color: black;
}
.btn-close:focus{
  color: black;
}
.title-transaction-filter{
font-size: 20px;
font-weight: bold;
color: #161A22;
}
.card-filter{
margin-inline-start: 10px ;
margin-inline-end: 20px;
/* border-radius: 10px !important; */
border-start-end-radius: 4px !important;
border-start-start-radius: 4px !important;
}

.btn-columns{
  background-color: transparent !important;
  color: black !important;
  border: 1px solid rgb(233, 230, 230) !important;
  width: 105px;
  height: 36px;
  border-radius: 4px;
}
.btn-filter{
  background-color: transparent !important;
  color: black !important;
  border: 1px solid rgb(233, 230, 230) !important;
border-radius: 4px;
width: 91px;
height: 36px;
border: none;
margin: 5px;

}
.dropdown{
  background-color: transparent  !important;
}
.title-transaction{
  font-weight: 600;
font-size: 14px;
color: var(--iq-title-text);
margin: 1.5rem;
}
.transaction-price{
  font-weight: 700;
font-size: 32px;
margin: 1.5rem;
/* font-family: Manrope */
}
.currency{
  font-weight: 400;
  font-size: 20px;
}
.user-list-table-transaction{
  background-color: white !important;
  /* box-shadow: 0 4px 12px 2px rgba(102, 102, 102, 0.16); */
  margin-top: 0px;
  /* border-radius: 8px; */
  border-end-start-radius: 8px !important;
  border-end-end-radius: 8px !important;
  margin-inline-end: 20px !important;
  margin-inline-start: 10px !important;
  margin-bottom: 20px !important;
}
.pages{
  display: flex;
  align-content: center;
  gap: 15px;
}

</style>
